import React from 'react';
import { Animated } from "react-animated-css";
import { useInView } from "react-intersection-observer";


export default function Contact(props) {
    const [ref, inView] = useInView({
        threshold: 0.8
    });
    if (inView.toString() === "true") {
        var urlHash = "/contact";
        window.history.pushState(null, null, urlHash);
        // let offer = document.getElementById("offer");
        // let classes = offer.classList;
        // classes.add("invisible");
    };

    return (
        <section id="contact" className="light"  ref={ref}>
            <Animated animationIn="anim-contact-header" animationInDuration={1000} animationInDelay={800} isVisible={true}>
                <div className="background-text">{ props.bigTitle }</div>
            </Animated>
            
            <div className="header contact-header-trigger reveal-animation">
                <span className="offer-header-span">{ props.title }</span>
            </div>

            <div className="description">
                <div className="anim-contact-line line-divider"></div>

                <Animated animationIn="anim-contact-header" animationInDuration={1000} animationInDelay={1200} isVisible={true}>
                    <div className="subtitle1">Do u have a <strong className="px-1">project</strong> for me?<br></br> You want to carry out an unusual order? Mail or call me!</div>
                </Animated>

            </div>
        </section>
    );
}