import React from 'react';
import { Animated } from "react-animated-css";
import { useInView } from "react-intersection-observer";
import ItemsList from './components/Panels';

const Items = [
    { icon: "web", title: "Web", subtitle: "Design", description: " I can offer You modern and original website solutions. Stand out amongst other sites! Showcase yourself from your best side! As a graphic designer, I will design the layout and implement it into production." },
    { icon: "visualid", title: "Visual", subtitle: "Identification", description: " A well-designed visual identification shows professionalism, builds and strengthens the brand image in the minds of customers. I will be happy to create projects for Your brand. Feel free to contact me!" },
    { icon: "print", title: "Projects", subtitle: "To be printed", description: " Printed materials, especially the ones in a big format, still have a big impact on the recipient's first impression. They attract the eyes of possible customers. Present your offer in a unique way designed by me." },
    { icon: "cms", title: "Management", subtitle: "Systems", description: " Expand the possibilities of your company by implementing functional and modern, simple management systems. Discover the potential of using databases designed strictly for Your company." }
];

export default function Offer(props) {
    const [ref, inView] = useInView({
        threshold: 0.8
    });
    if (inView.toString() === "true") {
        var urlHash = "/my-offer";
        window.history.pushState(null, null, urlHash);
        // let offer = document.getElementById("offer");
        // let classes = offer.classList;
        // classes.add("invisible");
    };

    return (
        <section id="offer" className="light"  ref={ref}>
            <Animated animationIn="anim-offer-header" animationInDuration={1000} animationInDelay={800} isVisible={true}>
                <div className="background-text">{ props.bigTitle }</div>
            </Animated>
            
            <div className="header offer-header-trigger reveal-animation">
                <span className="offer-header-span">{ props.title }</span>
            </div>
            
            <div className="description">
                <div className="line-divider anim-offer-line"></div>

                <Animated animationIn="anim-offer-header" animationInDuration={1000} animationInDelay={1200} isVisible={true}>
                    <div className="subtitle1">I will design a <strong className="px-1">complete</strong> project for You.<br></br>Are you ready to stand out and be unique?</div>
                </Animated>

                <Animated animationIn="anim-offer-desc" animationInDuration={1000} animationInDelay={600} sVisible={true}>
                    <div className="subtitle2">
                    As a freelance graphic designer, I design for small and large companies. I approach each order individually, 
                    and for each website design, logo design or comprehensive visual identification, I apply the principles of correct 
                    composition. In my projects, I care about legibility and ease of reception. Each new project is a challenge for me 
                    and I carry it out with full commitment.
                    </div>
                </Animated>      
            </div>
            
            <ItemsList items={Items} />
        </section>
    );
}