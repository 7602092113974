import React from 'react';
import { Animated } from "react-animated-css";
import { useInView } from "react-intersection-observer";

import SkillsList from './components/Skillslist';



export default function Skills(props) {
    
    const [ref, inView] = useInView({
        threshold: 0.8
    });
    if (inView.toString() === "true") {
        var urlHash = "/skills";
        window.history.pushState(null, null, urlHash);
        // let offer = document.getElementById("offer");
        // let classes = offer.classList;
        // classes.add("invisible");
    };

    return (
        <section id="skills" className="light" ref={ref}>
            <Animated animationIn="anim-skills-header" animationInDuration={1000} animationInDelay={800} isVisible={true}>
                <div className="background-text">{ props.bigTitle }</div>
            </Animated>
            
            <div className="header skills-header-trigger reveal-animation">
                <span className="skills-header-span">{ props.title }</span>
            </div>
            
            <div className="description">
                <div className="anim-skills-line line-divider"></div>

                <Animated animationIn="anim-skills-header" animationInDuration={1000} animationInDelay={1200} isVisible={true}>
                    <div className="subtitle1">What <strong className="px-1">technologies and languages</strong> I use while developing <br></br>websites and creating graphics. My soft skills.</div>
                </Animated>

            </div>

            <div className="skills-container">

                <div className="circles-container-box">

                    <div className="circles-container">
                        {/* <div id="inner-circle1" className="inner-circle">
                                <span className="">
                                    Tooltip text <br></br>Tooltip textTooltip text <br></br>Tooltip textTooltip textTooltip text
                                </span>
                        </div>
                        <div id="inner-circle2" className="inner-circle">
                                <span className="">Tooltip text</span>
                        </div>
                        <div id="inner-circle3" className="inner-circle">
                                <span className="">Tooltip text</span>
                        </div>
                        <div id="inner-circle4" className="inner-circle">
                                <span className="">Tooltip text</span>
                        </div>
                        <div id="inner-circle5" className="inner-circle">
                                <span className="">Tooltip text</span>
                        </div> */}
                    </div>

                    <div className="img-container">
                        <img src="images/skills.png" alt="Skills" />
                    </div>

                </div>

                <div className="bars-container">

                    <SkillsList id={1} name={'COMMUNICATION'} amount={14} />

                    <SkillsList id={2} name={'TEAMWORK'} amount={10} />

                    <SkillsList id={3} name={'PROBLEM SOLVING'} amount={13} />

                    <SkillsList id={4} name={'PLANNING'} amount={12} />

                    <SkillsList id={5} name={'TIME MANAGEMENT'} amount={11} />

                    <SkillsList id={6} name={'QUICK LEARNING'} amount={12} />

                    <SkillsList id={7} name={'CREATIVITY'} amount={11} />

                </div>
            </div>
        </section>
    );
}