import React from 'react';
import { Animated } from 'react-animated-css';
import { useInView } from 'react-intersection-observer';

import ItemsList from './components/Panels';

import '../../scss/Infinitysign.scss';

const n = 8;
let InfinitySign = [...Array(n)].map((e, i) => 
<div id={'infinity' + (i+1)} key={i} className="infinity">
    <div className="ball"></div>
    <div className="ball"></div>
</div>
);

const Items = [
    { title: "2+", subtitle1: "years", subtitle2: "of experience", last: false },
    { title: "50+", subtitle1: "finished", subtitle2: "projects", last: false },
    { title: "30+", subtitle1: "satisfied", subtitle2: "customers", last: false },
    { title: InfinitySign, subtitle1: "creative", subtitle2: "ideas", last: true }
];

export default function Aboutme(props) {
    const [ref, inView] = useInView({
        threshold: 0.9
    });
    if (inView.toString() === "true") {
        var urlHash = "/about-me";
        window.history.pushState(null, null, urlHash);
    };

    return (
        <section id="aboutme" className="dark aboutme-class" data-id={'about-me'} ref={ref}>
            <Animated animationIn="anim-aboutme-header" animationInDuration={1000} animationInDelay={800} isVisible={true}>
                <div className="background-text">{ props.bigTitle }</div>
            </Animated>
            <div className="top">
                <div className="header aboutme-header-trigger reveal-animation">
                    <span className="aboutme-header-span">{ props.title }</span>
                </div>
                
                <div className="line-0px-container">
                    <div className="line-0px anim-aboutme-line"></div>   
                </div>

                <div className="description">

                    <Animated animationIn="anim-aboutme-header" animationInDuration={1000} animationInDelay={1200} isVisible={true}>
                        <div className="subtitle1">My name is <strong className="px-1">Michał Dychtanowski</strong>. I am a freelance graphic and web designer.</div>
                    </Animated>

                    <Animated animationIn="anim-aboutme-desc" animationInDuration={1000} animationInDelay={600} sVisible={true}>
                        <div className="subtitle2">
                        Graphic and web design have been my hobby for about 2 years now. My adventure started with making simple edits to copyright free Wordrpess/Joomla/Webspell templates.
                        I’ve enjoyed doing it so much that I’ve decided to dive deeper into the world of web design 
                        and programming. I am ready to work independently or as a member of a team. 
                        I have great communication skills. Im an adept multitasker with the ability 
                        to work on simultaneous projects and bringing them to a completion with great efficiency and accuracy.
                        </div>
                    </Animated>      
                </div>
            </div>
            
            <ItemsList items={Items}/>
        </section> 
    );
}
