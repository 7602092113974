import React from 'react';
import { Animated } from "react-animated-css";

function ItemsList(props) {
    const Items = props.items;
    const listItems = Items.map((item, i) =>
        item.last === true ? 
            <Animated key={i} className="panel" animationIn="anim-aboutme-col-special" animationInDelay={i*200} animationInDuration={1000} isVisible={true}>
                <div className="last-item">
                    <div className="title">
                        <div className="infinity-wrapper">
                            {item.title} 
                        </div>

                        <button className="btn-goto" data-url="/grafik-komputerowy">
                            <span>Check my CV</span>
                        </button>
                    </div>
                    
                    <div className="subtitle">
                        {item.subtitle1}<br></br>{item.subtitle2}
                    </div>
                </div>
            </Animated>
        :
            <Animated key={i} className="panel" animationIn="anim-aboutme-col" animationInDelay={i*200} animationInDuration={1000} isVisible={true}>
                <div className="title">
                    {item.title}
                </div>
                <div className="subtitle">
                    {item.subtitle1}<br></br>{item.subtitle2}
                </div>
            </Animated>
    );
    return (
        <div className="grid">
            {listItems}
        </div>
    );
}

export default ItemsList;