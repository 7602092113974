import React from "react";

export default function Portfolioitem(props) {
    return (
        <>
            <div className="nav-wrapper">
                <a href={"/portfolio/" + props.previous} className="previous">
                    <span className="icon"><i className="fas fa-chevron-left"></i></span>
                    <span className="text">Prev</span>
                </a>
                <a href="/portfolio" className="close-item">
                    <span className="icon"><i className="fas fa-times"></i></span>
                    <span className="text">Close</span>
                </a>
                <a href={"/portfolio/" + props.next} className="next">
                    <span className="text">Next</span>
                    <span className="icon"><i className="fas fa-chevron-right"></i></span>
                </a>
            </div>
            <section id="headernoanim" className="dark">
                <div className="background-text">
                    SHAYENEK
                </div>
                <h1>{props.name}</h1>
                <h2>{props.description}</h2>

                <div className="nav-wrapper-mob">
                    <a href={"/portfolio/" + props.previous} className="previous">
                        <span className="icon"><i className="fas fa-chevron-left"></i></span>
                        <span className="text">Prev</span>
                    </a>
                    <a href="/portfolio" className="close-item">
                        <span className="icon"><i className="fas fa-times"></i></span>
                        <span className="text">Close</span>
                    </a>
                    <a href={"/portfolio/" + props.next} className="next">
                        <span className="text">Next</span>
                        <span className="icon"><i className="fas fa-chevron-right"></i></span>
                    </a>
                </div>
            </section> 
            <section id="portfolioitem" className="light">
                <div id="particles-js" style={{visibility: "hidden"}}></div>
                <p>{props.descriptionLong}</p>

                {props.monitor === true ? 
                    <div className="monitor">
                        <div className="web-img">
                            <img src={"projects/" + props.url + "/website.jpg"} alt={props.name}/>
                        </div>
                        <img src={"images/monitor.png"} className="bg-img" alt="Monitor" />
                    </div>
                : ""}

                {props.images.map((element, index) => <img src={"projects/" + props.url + "/" + element} key={index} alt={props.name}/>)}
            </section>
        </>
    );
}