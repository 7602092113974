import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';

import FooterNoAnim from "./components/FooterNoAnim";

import PortfolioItems from './sections/portfolio/components/Portfolioitems';
import Portfolioitem from './pages/Portfolioitem';




function Main() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects(PortfolioItems);
    }, []);

    return (
        <Router>
        <Switch>
            <Route exact path="/">
            <App />
            </Route>
            <Route exact path="/my-offer">
            <App />
            </Route>
            <Route exact path="/portfolio">
            <App />
            </Route>
            <Route exact path="/about-me">
            <App />
            </Route>
            <Route exact path="/skills">
            <App />
            </Route>
            <Route exact path="/contact">
            <App />
            </Route>
            {projects.map(item =>
                <Route key={item.id} exact path={"/portfolio/" + item.url}>
                    <Portfolioitem splash={item.splash} name={item.name} url={item.url} description={item.description} descriptionLong={item.descriptionLong} previous={item.previous} next={item.next} images={item.images} monitor={item.monitor} />
                    <FooterNoAnim />
                </Route>
            )}
        </Switch>
    </Router>
    );
};


ReactDOM.render(<Main />, document.getElementById('app'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

