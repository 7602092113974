import React from 'react';
import { Animated } from "react-animated-css";

function ItemsList(props) {
    const Items = props.items;
    const listItems = Items.map((item, i) =>
        <Animated key={i} className="panel" animationIn="animated-offer-col" animationInDelay={i*200} animationInDuration={1000} isVisible={true}>
            <svg className="icon">
                <use xlinkHref={"/images/svg/svgs.svg#" + item.icon} />
                {/* <use xlinkHref={"/images/svg/"+ item.icon +".svg#" + item.icon} /> */}
            </svg>
            <div className="title">
                <strong>{item.title}</strong><br></br>{item.subtitle}
            </div>
            <p>
                {item.description}
            </p>
        </Animated>
    );
    return (
        <div className="grid">
            {listItems}
        </div>
    );
}

export default ItemsList;