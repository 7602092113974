import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-scroll";



export default function NavBar(props) {
    return (
        <Navbar className="navbar navbar-dark scrolling-navbar navbar-gradient" expand="lg" fixed="top">
            <div className="button_container" id="toggle1"><span className="top"></span><span className="middle"></span><span className="bottom"></span></div>
            <div className="overlay-hamburger" id="overlay-hamburger">
                <nav className="overlay-menu">
                    <ul>
                        <li>
                            <Link href="#" className="nav-link scroll" id="toggle2" smooth={'easeInOutQuad'} to="home">Home</Link>
                        </li>
                        <li>
                            <Link href="#" className="nav-link scroll" id="toggle3" smooth={'easeInOutQuad'} to="offer">Offer</Link>
                        </li>
                        <li>
                            <Link href="#" className="nav-link scroll" id="toggle4" smooth={'easeInOutQuad'} to="portfolio">Portfolio</Link>
                        </li>
                        <li>
                            <Link href="#" className="nav-link scroll" id="toggle5" smooth={'easeInOutQuad'} to="aboutme">About me</Link>
                        </li>
                        <li>
                            <Link href="#" className="nav-link scroll" id="toggle6" smooth={'easeInOutQuad'} to="skills">Skills</Link>
                        </li>
                        <li>
                            <Link href="#" className="nav-link scroll" id="toggle7" smooth={'easeInOutQuad'} to="contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container">
                <Navbar.Brand className="text-white">
                    <a href="/" className="text-decoration-none text-light">{ props.logo }</a>
                </Navbar.Brand>
                {/* <Navbar.Toggle className="second-button" aria-controls="responsive-navbar-navn">
                <div className="animated-icon2"><span></span><span></span><span></span><span></span></div>
                </Navbar.Toggle> */}
                {/* <button aria-controls="responsive-navbar-navn" type="button" aria-label="Toggle navigation" className="navbar-toggler second-button" data-target="responsive-navbar-navn">
                    <div className="animated-icon2"><span></span><span></span><span></span><span></span></div>
                </button> */}

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <ul className="navbar-nav">
                            {/* <li className="nav-item" id="nav1" >
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="home" data-toggle="tooltip" data-placement="right" title="Tooltip on right">Home</Link>
                            </li> */}
                            <li className="nav-item" id="nav1" >
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="home">Home</Link>
                            </li>
                            <li className="nav-item" id="nav2">
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="offer">Offer</Link>
                            </li>
                            <li className="nav-item" id="nav3">
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="portfolio">Portfolio</Link>
                            </li>
                            <li className="nav-item" id="nav4">
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="aboutme">About me</Link>
                            </li>
                            <li className="nav-item" id="nav5">
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="skills">Skills</Link>
                            </li>
                            <li className="nav-item" id="nav6">
                                <Link className="nav-link scroll" smooth={'easeInOutQuad'} to="contact">Contact</Link>
                            </li>
                            
                            {/* <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle text-white" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="#">Contact</Link>
                                <div className="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">
                                    <button className="dropdown-item" href="#">Action</button>
                                    <button className="dropdown-item" href="#">Another action</button>
                                    <button className="dropdown-item" href="#">Something else here</button>
                                </div>
                            </li> */}
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    )
}
