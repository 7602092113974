import React from 'react';
import { Animated } from "react-animated-css";
import { useInView } from "react-intersection-observer";

import PortfolioContainer from "./components/PortfolioContainer";

export default function Portfolio(props) {
    const [ref, inView] = useInView({
        threshold: 0.6
    });
    if (inView.toString() === "true") {
        var urlHash = "/portfolio";
        window.history.pushState(null, null, urlHash);
    };

    return (
        <section id="portfolio" className="light" ref={ref}>
            <Animated animationIn="anim-portfolio-header" animationInDuration={800} animationInDelay={800} isVisible={true}>
                <div className="background-text">{ props.bigTitle }</div>
            </Animated>

            <div className="header portfolio-header-trigger reveal-animation">
                <span className="cover"></span>
                <span className="portfolio-header-span">{ props.title }</span>
            </div>

            <div className="description">
                <div className="line-divider anim-portfolio-line"></div>
                
                <Animated animationIn="anim-portfolio-header" animationInDuration={1000} animationInDelay={1200} isVisible={true}>
                    <div className="subtitle1">Projects that <strong className="px-1">I have</strong> finished recently as a freelance <br></br>graphic designer. Feel free to browse!</div>
                </Animated>
            </div>
            
            <div className="portfolio-grid">
                <PortfolioContainer />
            </div>
        </section>
    );
}
