import React from 'react';
import { Link } from "react-scroll";
import { useInView } from "react-intersection-observer";

export default function Hero() {
    const [ref, inView] = useInView({
        threshold: 0.7
    });
    if (inView.toString() === "true") {
        var urlHash = "/";
        window.history.pushState(null, null, urlHash);
    };

    return (
        <section id="home" className="dark fullscreen" data-id={'home'} ref={ref}>
            <div id="wrapper">
                <div className="title-text">
                    <div className="animatable">Hello, my name is <strong>Michał Dychtanowski</strong>!</div>
                </div>
                <div className="home-subTitle">
                    <div className="animatable-sub1 subtitle-text">I am a <strong>Freelance</strong></div>&nbsp;
                    <div className="animatable-sub2 subtitle-text"><strong>Graphic Designer</strong></div>&nbsp;
                    <div className="animatable-sub3 subtitle-text">At your service<strong>.</strong></div>
                </div>

                <div className="home-button">
                    <Link to="aboutme" delay={100} smooth duraton={500}>
                        <button className="btn-goto animatable-button">
                            <span className="animatable-button">Learn more about me</span>
                        </button>
                    </Link>
                </div>
            </div>

            <div className="animatable-pic">
                <img src={'/images/me.png'} alt="MEEEEE" />
            </div> 
        </section>
    );
}