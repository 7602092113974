import React from 'react';


export default function Footer(props) {
    return (
        <section id="footer" className="dark">
            <>
                <div className="background-text">
                    S
                </div>
            </>
            <div className="description">
                <div className="grid">
                    <div className="panel">
                        <div className="title">
                            <strong>
                                <img src="images/icons/contact.png" alt="Call me!" />
                            </strong>
                        </div>
                        <p>
                            <a href="tel:+48 508 665 315">+48 508 665 315</a>
                        </p>
                    </div>

                    <div className="panel">
                        <div className="title">
                            <strong>
                                <img src="images/icons/mailme.png" alt="Mail me!" />
                            </strong>
                        </div>
                        <p>
                            <a href="mailto:kontakt@shayenek.pl">kontakt@shayenek.pl</a>
                        </p>
                    </div>

                    <div className="panel">
                        <div className="title">
                            <strong>
                                <img src="images/icons/map.png" alt="You can find me here!" />
                            </strong>
                        </div>
                        <p>
                            <a href="/">Wrocław, Poland</a>
                        </p>
                    </div>

                    <div className="panel">
                        <div className="title">
                            <strong>
                                <img src="images/icons/form.png" alt="Use my contact form" />
                            </strong>
                        </div>
                        <p>
                            <a href="/">USE MY CONTACT FORM</a>
                        </p>
                    </div>
                </div>

                <hr style={{width: "100%"}} />

                <div className="justify-content-between">
                    ©2020 Shayenek.pl - Freelance Web Developer, Graphics Designer
                </div>
            </div>
        </section> 
    );
}
