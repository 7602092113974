import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Animated } from "react-animated-css";

import PortfolioItems from '../components/Portfolioitems';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


function Label(props) {
    return (
        <Animated animationIn="anim-portfolio-tablabel" animationInDuration={1000} animationInDelay={props.delay} isVisible={true}>
            {props.title}
        </Animated>
    );
}

function PortfolioContainer() {
    const [filter, setFilter] = useState("favorite");
    const [projects, setProjects] = useState([]);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setProjects(PortfolioItems);
    }, []);

    useEffect(() => {
        setProjects([]);

        const filtered = PortfolioItems.map(p => ({
            ...p,
            filtered: p.category.includes(filter)
        }));
        setProjects(filtered);
    }, [filter]);
    
    return (
        <div className={classes.root}>
            <AppBar position="static" className="shadow-none">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs" centered >
                    {/* <Tab label={<Label title={'favorite'} delay={200}/>} active={filter === "all"} onClick={() => setFilter("favorite")} />
                    <Tab label={<Label title={'frontend'} delay={400}/>} active={filter === "frontend"} onClick={() => setFilter("frontend")} />
                    <Tab label={<Label title={'mobile'} delay={600}/>} active={filter === "mobile"} onClick={() => setFilter("backend")} />
                    <Tab label={<Label title={'identification'} delay={800}/>} active={filter === "identification"} onClick={() => setFilter("identification")} />
                    <Tab label={<Label title={'print'} delay={1000}/>} active={filter === "print"} onClick={() => setFilter("print")} />
                    <Tab label={<Label title={'video/animation'} delay={1200}/>} active={filter === "video/animation"} onClick={() => setFilter("video/animation")} /> */}

                    <Tab label={<Label title={'favorite'} delay={200}/>} active={value ? 1 : 0} onClick={() => setFilter("favorite")} />
                    <Tab label={<Label title={'frontend'} delay={400}/>} active={value ? 1 : 0} onClick={() => setFilter("frontend")} />
                    <Tab label={<Label title={'backend'} delay={600}/>} active={value ? 1 : 0} onClick={() => setFilter("backend")} />
                    <Tab label={<Label title={'identification'} delay={800}/>} active={value ? 1 : 0} onClick={() => setFilter("identification")} />
                    <Tab label={<Label title={'print'} delay={1000}/>} active={value ? 1 : 0} onClick={() => setFilter("print")} />
                    <Tab label={<Label title={'other'} delay={1200}/>} active={value ? 1 : 0} onClick={() => setFilter("other")} />
                </Tabs>
                    <div className="grid">
                        {projects.map(item =>
                            item.filtered === true ? 
                            <Animated key={item.id} animationIn="anim-portfolio-item" animationInDuration={600} animationInDelay={item.id*300} animateOnMount={true}>
                                <div id={item.id} className="item">
                                    <a href={"/portfolio/" +  item.url} className="hover text-decoration-none">
                                        <span className="overlay">
                                            <span className="name">{item.name}</span>
                                            <span className="item-description">{item.description}</span>
                                            <span className="tags">{item.category.map((element, index) => <span key={index} className="item_category">{element}</span> )}</span>
                                        </span>
                                    </a>
                                    <img src={"projects/" + item.url + "/splash.jpg"} alt={item.name} />
                                </div> 
                            </Animated>
                            : ""
                        )}
                    </div>
            </AppBar>
        </div>
    );
}

export default PortfolioContainer;
