import React from 'react';
import { Animated } from 'react-animated-css';


export default function Footer(props) {
    return (
        <section id="footer" className="dark">
            <Animated animationIn="anim-contact-col" animationInDelay={600} animationInDuration={1000} isVisible={true}>
                <div className="background-text">
                    S
                </div>
            </Animated>
            <div className="description">
                <div className="grid">
                    <Animated className="panel" animationIn="anim-contact-col" animationInDelay={200} animationInDuration={1000} isVisible={true}>
                        <div className="title">
                            <strong>
                                <img src="images/icons/contact.png" alt="Call me!" />
                            </strong>
                        </div>
                        <p>
                            <a href="tel:+48 508 665 315">+48 508 665 315</a>
                        </p>
                    </Animated>

                    <Animated className="panel" animationIn="anim-contact-col" animationInDelay={400} animationInDuration={1000} isVisible={true}>
                        <div className="title">
                            <strong>
                                <img src="images/icons/mailme.png" alt="Mail me!" />
                            </strong>
                        </div>
                        <p>
                            <a href="mailto:kontakt@shayenek.pl">kontakt@shayenek.pl</a>
                        </p>
                    </Animated>

                    <Animated className="panel" animationIn="anim-contact-col" animationInDelay={600} animationInDuration={1000} isVisible={true}>
                    <div className="title">
                        <strong>
                            <img src="images/icons/map.png" alt="You can find me here!" />
                        </strong>
                        </div>
                        <p>
                            <a href="/">Wrocław, Poland</a>
                        </p>
                    </Animated>

                    <Animated className="panel" animationIn="anim-contact-col" animationInDelay={600} animationInDuration={1000} isVisible={true}>
                    <div className="title">
                        <strong>
                            <img src="images/icons/form.png" alt="Use my contact form" />
                        </strong>
                        </div>
                        <p>
                            <a href="/">USE MY CONTACT FORM</a>
                        </p>
                    </Animated>
                </div>

                <hr />

                <Animated className="justify-content-between anim-contact-footer">
                    ©2020 Shayenek.pl - Freelance Web Developer, Graphics Designer
                </Animated>
            </div>
        </section> 
    );
}
