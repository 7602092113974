import React from 'react';

export default function SkillsList(props) {
    const Amount = props.amount;
    const Name = props.name;
    const Id = props.id;

    const skills = [...Array(Amount)].map((e, i) => 
        <div className="dot" key={Id+i+6}>
            <span className="dot-empty" data-delay={Id+i+6}></span>
            <span className="dot-highlighted" data-delay={Id+i+10}></span>
        </div>
    )

    const skillsEmpty = [...Array(14-Amount)].map((e, k) => 
        <div className="dot" key={Amount+(Id+k)+6}>
            <span className="dot-empty" data-delay={Amount+(Id+k)+6}></span>
        </div>
    )

    
    const merged = skills.concat(skillsEmpty);

    
    return (
    <div className="skill">
        <div className="skill-title">
            <span>{Name}</span>
            <div className="arrow"></div>
        </div>
        <div className="dots">
            {merged}
        </div>
    </div>
    );
}
