import React, { Component } from 'react';
// import { Animation } from "./Animation";
import './App.css';
import './css/Animatable.css';
import './scss/Main.scss';
import './scss/Stars.scss';
import '../node_modules/mdbootstrap/css/mdb.min.css';
// import { HashLink as Link} from 'react-router-hash-link';

import Offer from "./sections/offer/Offer";
import Portfolio from "./sections/portfolio/Portfolio";
import Aboutme from "./sections/aboutme/Aboutme";
import Skills from "./sections/skills/Skills";
import Contact from "./sections/contact/Contact";

import NavBar from "./components/Navbar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Shayenek Portfolio',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'Aboout Me', path: '/about' },
        { title: 'Contact', path: '/contact' }
      ],
      home: {
        title: 'Hello!',
        subTitle: 'My name is Michał Dychtanowski',
        text: 'Welcome to my portfolio!'
      }
    }
  }

  render() {
    return (
      <>
        <div id="loader-wrapper">
          <div id="loader"></div>
          
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>

        </div>
        
          <div id="particles-js" style={{zIndex: 1}}></div>
          <NavBar logo={'| SHAYENEK |'} />
          <Hero />
          
          <Offer title={'What I do'} bigTitle={'MyOffer'}/>

          <Portfolio title={'Portfolio'} bigTitle={'Projects'} />

          <Aboutme title={'About me'} bigTitle={'InBrief'} />

          <Skills title={'My skills'} bigTitle={'Proficiency'} />

          <Contact title={'Contact'} bigTitle={'WorkWithMe'} />

          <Footer />
      </>
    );
  }
}

export default App;
